import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { ContactInfo, Map, ContactModal } from "page_components/contact"

const Contact = ({ data }) => {
  const [contactModal, setContactModal] = useState(false)

  const breadcrumbs_data = [
    {
      name: "Kontakt",
      href: "/kontakt",
    },
  ]

  return (
    <Layout
      seo={{
        title: "Kontakt",
        description:
          "Podolog. Umów wizytę telefonicznie lub online, tel.: 500 301 420, ul. Kolejowa 68, Nowy Targ",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Kontakt" />
      <ContactInfo
        data={data?.allWpPage?.nodes[0]?.acfContact}
        setContactModal={setContactModal}
      />
      <Map />
      {contactModal && (
        <ContactModal handleClose={() => setContactModal(false)} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo5NzQ=" } }) {
      nodes {
        acfContact {
          address
          opening
        }
      }
    }
  }
`

export default Contact
